export function getMessageHistory() {
  // Tente obter messageHistory do localStorage, se não encontrar use um array vazio
  let storedMessageHistory;
  try {
    storedMessageHistory = JSON.parse(localStorage.getItem('messageHistory'));
  } catch (error) {
    console.error('Erro ao buscar messageHistory do localStorage', error);
  }
  const messageHistory = storedMessageHistory || [];

  return messageHistory;
}

export function getMessageHistoryActive(getMessageMaxCount) {
  const messageHistory = getMessageHistory();
  const messageHistoryActive =
    getMessageMaxCount() === 0
      ? messageHistory
      : messageHistory.slice(0, getMessageMaxCount());

  return messageHistoryActive;
}

export function getMessageMaxCount() {
  const messageMaxCount = sessionStorage.getItem('messageMaxCount');
  if (messageMaxCount === null) {
    sessionStorage.setItem('messageMaxCount', '0');
    return 0;
  } else {
    return Number(messageMaxCount);
  }
}
