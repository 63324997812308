import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Encoder from './layouts/Encoder';
// import NotFound from './pages/NotFound';
import { FormDataProvider } from './controller/FormData';

// import css
import './assets/css/remixicon.css';

// import scss
import './scss/style.scss';

// set skin on load
window.addEventListener('load', function () {
  let skinMode = localStorage.getItem('skin-mode');
  let HTMLTag = document.querySelector('html');

  if (skinMode) {
    HTMLTag.setAttribute('data-skin', skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <FormDataProvider>
          <Routes>
            <Route path="/" element={<Encoder />} />
            <Route path="/:taskId" element={<Encoder />} />
          </Routes>
        </FormDataProvider>
        {/* <Routes>
          <Route path="*" element={<NotFound />} />
        </Routes> */}
      </BrowserRouter>
    </React.Fragment>
  );
}
