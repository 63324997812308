import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import Footer from '../layouts/Footer';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import MessageHistory from '../components/Messagehistory';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FormData } from '../controller/FormData';
import { getMessageHistory, getMessageMaxCount } from '../components/MessageList';

export default function Encoder() {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const[selection, setSelection] = useState()

  const {
    lastInput,
    setlastInput,
    decodedMessage,
    setdecodedMessage,
    encodedMessage,
    setencodedMessage,
    inputTitle,
    setinputTitle,
  } = useContext(FormData);


  const titleRef = useRef(null);
  const decodeRef = useRef(null);
  const inputRef =
    lastInput === 'inputTitle' ? titleRef : lastInput === 'decodedMessage' ? decodeRef : null;
  
  useEffect(() => {
    if (!selection || !inputRef) return; // Verifica se selection e inputRef existem
  
    const { start, end } = selection;
    inputRef.current.focus();
    inputRef.current.setSelectionRange(start, end);
  }, [selection, inputRef]);
  
    

  const handleEmojiInsert = (event) => {
    if (!inputRef) return;
    const start = inputRef.current.selectionStart;
    const end = inputRef.current.selectionEnd;
  
    let newValue;
    if (lastInput === 'inputTitle') {
      newValue = inputTitle.substring(0, start) + event.emoji + inputTitle.substring(end);
      setinputTitle(newValue);
    } else if (lastInput === 'decodedMessage') {
      newValue = decodedMessage.substring(0, start) + event.emoji + decodedMessage.substring(end);
      setdecodedMessage(newValue);
    }
  
    inputRef.current.focus();
    const newSelectionStart = start + event.emoji.length;
    setSelection({ start: newSelectionStart, end: newSelectionStart });
  };
  

  //Função que gera um nova mensagem no storage
  const addMessage = () => {
    let messageHistory =
      JSON.parse(localStorage.getItem('messageHistory')) || [];
    const lastId = getMaxId(messageHistory);
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('pt-BR', { month: 'short' });
    const newid = Number(lastId) + 1;
    const newMessage = {
      id: newid,
      day: day,
      month: month,
      title: '',
      content: '',
    };

    // Ordena as mensagens por ID decrescente,getMessageMaxCount e remove o objeto com menor ID
    messageHistory.push(newMessage);
    messageHistory.sort((a, b) => b.id - a.id);
    if (messageHistory.length > getMessageMaxCount) {
      messageHistory.pop();
    }
    localStorage.setItem('messageHistory', JSON.stringify(messageHistory));
    navigate(`/${newid}`);
    return newid;
  };
  
  //Funcao de nova mensagem no botão newmessage
  function handleNew() {
    setIsLoading(true);
    setinputTitle('');
    setdecodedMessage('');
    setencodedMessage('');
    addMessage();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }

  function encodeWith() {
    const regex = /\{(.*?)\}+/g;
    const arr = decodedMessage.match(regex);
    let unencoded = decodedMessage;
    let i = 0;
    arr.forEach(function (entry) {
      unencoded = unencoded.replace(entry, 'tokenhere' + i);
      i++;
    });
    unencoded = encodeURIComponent(unencoded)
      .replace(/'/g, '%27')
      .replace(/"/g, '%22');
    i = 0;
    arr.forEach(function (entry) {
      unencoded = unencoded.replace('tokenhere' + i, entry);
      i++;
    });
    return unencoded;
  }

  function encodeWithout() {
    if (decodedMessage) {
      const encoded = decodedMessage;
      setencodedMessage(encoded);
      return encodeURIComponent(encoded);
    }
  }

  function decode() {
    if (encodedMessage) {
      const decoded = decodeURIComponent(encodedMessage);
      setdecodedMessage(decoded);
      return decoded;
    }
  }

  //Função onclick no botão 'encoded'
  function handleEncode() {
    console.log(setIsLoading());
    setIsLoading(true);
    // setErrorMessage('');

    if (decodedMessage.includes('{')) {
      setencodedMessage(encodeWith());
      setIsLoading(false);
    } else {
      try {
        setencodedMessage(encodeWithout());
        setIsLoading(false);
      } catch (error) {
        // setErrorMessage(error.message);
        setIsLoading(false);
      }
    }
  }

  //Funcao on click no botão 'decode'
  function handleDecode() {
    setIsLoading(true);
    // setErrorMessage('');
    try {
      setdecodedMessage(decode());
      setIsLoading(false);
    } catch (error) {
      // setErrorMessage(error.message);
      setIsLoading(false);
    }
  }

  const getMaxId = (messageHistory) => {
    if (messageHistory.length === 0) {
      return 0;
    }
    return Math.max(...messageHistory.map((item) => item.id));
  };

  const messageHistory = getMessageHistory();

  // Função que verifica se uma mensagem e nova, e a salva quando 'onblur' nos campos titulo ou decoded.
  const saveMessage = () => {
    let messageHistory =
      JSON.parse(localStorage.getItem('messageHistory')) || [];
    const lastId = getMaxId(messageHistory);
    const newid = Number(lastId) + 1;
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('pt-BR', { month: 'short' });
    const focusId = isNaN(Number(taskId))
      ? Number(newid)
      : Number(taskId);

    // Encontra o índice do objeto com o ID desejado
    const targetIndex = messageHistory.findIndex(
      (item) => Number(item.id) === Number(focusId),
    );

    // Atualiza o objeto do payload da mensagem.
    const finalId = targetIndex !== -1 ? focusId : newid;
    const focusMessage = {
      id: finalId,
      day: day,
      month: month,
      title: inputTitle,
      content: decodedMessage,
    };

    let navid = '';
    if (targetIndex !== -1) {
      messageHistory[targetIndex] = focusMessage;
      navid = focusId;
    } else {
      navid = newid;
      messageHistory.push(focusMessage);
    }

    messageHistory.sort((a, b) => b.id - a.id);
    if (messageHistory.length > getMessageMaxCount()) {
      messageHistory.pop();
    }
    localStorage.setItem('messageHistory', JSON.stringify(messageHistory));
    navigate(`/${navid}`);
  };


  const fillForm = useCallback((messageId) => {
    //console.log('condicao da task ', messageId);
    if (messageId && !isNaN(Number(messageId))) {
      const task = messageHistory.find(
        (item) => Number(item.id) === Number(messageId),
      );
      if (task) {
        setinputTitle(task.title);
        setdecodedMessage(task.content);
        setencodedMessage('');
      } else {
        setinputTitle('Mensagem não encontrada');
        setdecodedMessage(
          'Mensagem não encontrada, pode ter sido apagada ou estar fora do limite de seu plano',
        );
      }
    } else {
      const task =
        messageHistory.length > 0 ? Math.max(...messageHistory.map(item => Number(item.id))) : '';
      navigate(`/${task}`);
      console.log('task erro NAN', task);
    }
  }, [messageHistory, navigate, setinputTitle, setdecodedMessage, setencodedMessage]);
  
  useEffect(() => {
    fillForm(taskId);
  }, [taskId]);

  return (
    <section>
      {/* <FormDataProvider> */}
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <Row className="row-cols-auto g-4 g-xxl-5">
          <Col xl>
            <h2 className="main-title fs-4 mb-1">
              URL Encoder for Mautic Variables
            </h2>
            <p className="text-secondary mb-4">
              Encode all messages in URLencoded exception Mautic variable tokens.
            </p>

            <Row className="g-3 g-lg-3 d-flex justify-content-between">
              <Col md className="mt-5 mt-md-3 mt-lg-4 col-md-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <label className="task-label">Histórico</label>
                  <div className="dropdown dropdown-task">
                    <Link to="" className="dropdown-link">
                      Date Added <i className="ri-arrow-down-s-line"></i>
                    </Link>
                  </div>
                </div>

                <MessageHistory taskId={taskId} navigate={navigate} />
              </Col>
              <Col md className="mt-5 mt-md-3 mt-lg-4 col-md-5">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <label className="task-label">
                    Message{' '}
                    {isLoading && (
                      <span
                        id="Messageloading"
                        className="spinner-border"
                        role="status"
                        style={{
                          height: '0.75rem',
                          width: '0.75rem',
                          borderWidth: '0.2rem',
                        }}
                      ></span>
                    )}
                  </label>
                </div>

                {/* ------------------------------ */}

                <form
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <div className="mb-3">
                      <input
                        type="text"
                        id="inputTitle"
                        ref={titleRef}
                        name="inputTitle"
                        value={inputTitle}
                        onChange={(event) => setinputTitle(event.target.value)}
                        onBlur={saveMessage}
                        onFocus={(event) => setlastInput(event.target.id)}
                        className="form-control"
                        style={{ fontWeight: 'bold' }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="decodedMessage" className="form-label">
                        Decoded:
                        {decodedMessage ? (
                          <span className="small text-danger">
                            {' '}
                            {decodedMessage.length} caracteres.
                          </span>
                        ) : null}
                      </label>
                      <textarea
                        id="decodedMessage"
                        ref={decodeRef}
                        name="decodedMessage"
                        value={decodedMessage}
                        onChange={(event) => setdecodedMessage(event.target.value)}
                        onBlur={saveMessage}
                        onFocus={(event) => setlastInput(event.target.id)}
                        className="form-control"
                        style={{ height: '30vh' }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="encodedMessage" className="form-label">
                        Encoded:
                      </label>
                      <textarea
                        id="encodedMessage"
                        name="encodedMessage"
                        value={encodedMessage}
                        onChange={(event) => setencodedMessage(event.target.value)}
                        className="form-control"
                        style={{ height: '30vh' }}
                      />
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="btn-group" role="group">
                        <button className="btn btn-primary me-2" onClick={handleEncode}>
                          Encode <i className="ri-arrow-down-line"></i>
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary me-2"
                          onClick={handleDecode}
                        >
                          Decode <i className="ri-arrow-up-line"></i>
                        </button>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-secondary ml-auto disable"
                        // disabled
                        onClick={handleSelection}
                      >
                        Preview
                      </button> */}
                      <button
                        type="button"
                        className="btn btn-danger ml-auto"
                        onClick={handleNew}
                      >
                        <i className="ri-draft-line"></i> New Message
                      </button>
                    </div>
                  </form>

                  {/* ------------------------------ */}

              </Col>
              <Col className="mt-5 mt-md-3 mt-lg-4 col-md-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <label className="task-label">Emojis </label>
                </div>
                <EmojiPicker
                  searchDisabled={false}
                  emojiVersion={15.0}
                  size={16}
                  emojiStyle={EmojiStyle.APPLE}
                  height="70vh"
                  width="100%"
                  onEmojiClick={handleEmojiInsert}
                  data-emojiable={true}
                  lazyLoadEmojis={true}
                />

                <div className="mb-4 mb-xxl-5"></div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <label className="task-label">Configure </label>
                </div>

                <Card className="card-one rounded border">
                  <Card.Header>
                    <Card.Title as="h6">
                      Preserve Mautic {'{ tokens }:'}
                    </Card.Title>
                  </Card.Header>
                  <Card.Header>
                    <Card.Title as="h6">Auto encode on focus out</Card.Title>
                  </Card.Header>
                  <Card.Header>
                    <Card.Title as="h6">Mautic token helper</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </section>
  );
}
