import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. BORLOT DESING E SERVICOS EIRELI - ME. All Rights Reserved.</span>
      <span>Created by: <Link to="http://leoborlot.com.br" target="_blank">Leo Borlot</Link></span>
    </div>
  )
}