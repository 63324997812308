import React, { createContext, useState } from 'react';

// Crie um contexto para compartilhar os hooks
export const FormData = createContext();

export const FormDataProvider = ({ children }) => {
  // Defina os estados usando os hooks useState
  const [decodedMessage, setdecodedMessage] = useState('');
  const [encodedMessage, setencodedMessage] = useState('');
  const [inputTitle, setinputTitle] = useState('');
  const [lastInput, setlastInput] = useState('');


  return (
    // Inclua o contexto em torno dos componentes filhos
    <FormData.Provider
      value={{
        lastInput,
        setlastInput,
        decodedMessage,
        setdecodedMessage,
        encodedMessage,
        setencodedMessage,
        inputTitle,
        setinputTitle,
      }}
    >
      {/* Renderize os componentes filhos */}
      {children}
    </FormData.Provider>
  );
};
