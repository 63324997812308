import React, { useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// import { FormData } from '../controller/FormData';
import { getMessageHistoryActive } from '../components/MessageList';

export default function MessageHistory({ taskId }) {
  const navigate = useNavigate();

  // const {
  //   // decodedMessage,
  //   // setdecodedMessage,
  //   // encodedMessage,
  //   // setencodedMessage,
  //   // inputTitle,
  //   // setinputTitle,
  // } = useContext(FormData);

  // Executa a leitura do histórico
  const loadHistory = useCallback(() => {
    const messageHistoryJSON = localStorage.getItem('messageHistory');
    if (messageHistoryJSON) {
      const messageHistory = JSON.parse(messageHistoryJSON);
      messageHistory.sort((a, b) => b.id - a.id);
      const maxIdObj = messageHistory[0];

      const taskId = maxIdObj.id;
      navigate(`/${taskId}`);
    }
  }, [navigate]);

  // Monitora mudanças no taskId
  useEffect(() => {
    if (!taskId) {
      loadHistory();
    }
  }, [taskId, loadHistory]);

  // Monitora mudanças no taskId
  // const loadMessage = (id) => {
  //   console.log(`Leu a mensagem: ${id}`);
  // };

  useEffect(() => {
    //loadMessage(taskId.taskId);
  }, [taskId]);

  // SETUP DO NUMERO DE MENSAGENS NA TELA
  sessionStorage.setItem('messageMaxCount', '10');

  const getMessageMaxCount = useCallback(() => {
    const messageMaxCount = sessionStorage.getItem('messageMaxCount');
    if (messageMaxCount === null) {
      sessionStorage.setItem('messageMaxCount', '0');
      return 0;
    } else {
      return Number(messageMaxCount);
      //return parseInt(messageMaxCount, 10);
    }
  }, []);

  // Executa a função na leitura do componente.
  useEffect(() => {
    getMessageMaxCount();
  }, [getMessageMaxCount]);

  // const messageHistory = getMessageHistory();
  const messageHistoryActive = getMessageHistoryActive(getMessageMaxCount);

  return (
    <Card className="card-one border">
      <Card.Body className="p-3">
        <ul className="events-list">
          {messageHistoryActive.map((item) => (
            <Link to={`/${item.id}`} key={item.id}>
              <li
                className={
                  Number(taskId) === Number(item.id) ? 'active my-1' : 'my-1'
                }
              >
                <div className="event-date">
                  <small>{item.month}</small>
                  <h5>{item.day}</h5>
                </div>
                <div className="events-body" style={{ overflow: 'hidden' }}>
                  <div className="ev-item">
                    <h6>{item.title.slice(0, 22) + '...'}</h6>
                    <p>
                      {item.content.length === 0 ? (
                        <>
                          {' '}
                          {'...'} <br /> {'...'}{' '}
                        </>
                      ) : (
                        item.content.slice(0, 120) + '...'
                      )}
                    </p>
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
}
